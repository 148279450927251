import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import log from "loglevel";

if (process.env.NODE_ENV === "production") {
  log.setLevel("warn");
}

// Override console.log function in production mode
// if (process.env.NODE_ENV === "production") {
//   console.log = function () {
//     if (console.warn.apply) {
//       console.warn.apply(console, arguments);
//     }
//   };
// }

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
