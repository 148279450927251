import * as React from "react";
import {
  VStack,
  HStack,
  Heading,
  Text,
  Tag,
  Link,
  Tooltip,
  useColorModeValue,
  // Icon,
  // Flex,
  Image,
  // Badge,
  // Box,
} from "@chakra-ui/react";
// import { Link as NavLink } from "react-router-dom";
// import { FaEye } from "react-icons/fa";
import { getTagColor } from "style/theme";
import hashnode from "assets/images/logos/hashnode.png";
// import dev2 from "assets/images/logos/hashnode.png";
import { StaggerChildren } from "components/page-transitions";
import { DevToArticle } from "./hashnode-models";

export interface PostCardProps {
  article: DevToArticle;
}

const PostCard = ({ article }) => {
  const textColor = useColorModeValue("gray.500", "gray.200");
  const hashnodeIcon = useColorModeValue(hashnode, hashnode);

  const dateTime = new Date(article.published_at);

  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = dateTime.toLocaleDateString("en-US", options);

  return (
    <StaggerChildren>
      <VStack
        spacing={1}
        p={4}
        //isExternal #todo
        _hover={{ shadow: "md", textDecoration: "none" }}
        borderWidth="1px"
        position="relative"
        rounded="md"
        bg={useColorModeValue("white", "gray.800")}
        align="left"
      >
        {/* {article.external ? ( */}
        <Tooltip hasArrow label="Hashnode" placement="top">
          <Image
            src={hashnodeIcon}
            width="2rem"
            height="2rem"
            position="absolute"
            color="#cbd5e0"
            right="0.5rem"
            top="-14px"
          />
        </Tooltip>
        {/* // ) : (
        //   <Tooltip hasArrow label="pvishnuprasaath.github.io" placement="top">
        //     <Box position="absolute" color="#cbd5e0" right="0.5rem" top="-14px">
        //       <Badge ml="1" variant="solid" colorScheme="blackAlpha">
        //         Website
        //       </Badge>
        //     </Box>
        //   </Tooltip>
        // )} */}
        <Heading fontSize="lg" /*align="left" #todo*/ mt={0} textAlign="left">
          {/* {article.external ? ( */}
          <Text as={Link} href={article.canonical_url} target="_blank">
            {article.title}
          </Text>
          {/* // ) : (
          //   <Link as={NavLink} to={article.link}>
          //     {article.title}
          //   </Link>
          // )} */}

          {/* {article.isNew && (
            <Badge
              ml="1"
              mb="1"
              colorScheme="green"
              fontSize="0.7em"
              lineHeight={1.5}
            >
              New
            </Badge>
          )} */}
        </Heading>
        <HStack spacing={2} isInline>
          <Tooltip hasArrow label="Published" placement="top">
            <Text fontSize="sm" fontWeight="400" color={textColor}>
              {formattedDate}
            </Text>
          </Tooltip>

          {/* <Tooltip hasArrow label="Views" placement="top">
            <Flex alignItems="center">
              <Text
                fontSize="sm"
                noOfLines={1}
                fontWeight="400"
                align="left"
                color={textColor}
              >
                {article.views}
              </Text>
              <Icon as={FaEye} ml={1} color={textColor} />
            </Flex>
          </Tooltip>*/}
          <Text fontSize="sm" fontWeight="600" color={textColor}>
            •
          </Text>
          <Tooltip hasArrow label="Read time" placement="top">
            <Text
              fontSize="sm"
              noOfLines={1}
              fontWeight="400"
              align="left"
              color={textColor}
            >
              {article.reading_time_minutes} mins read
            </Text>
          </Tooltip>
          <Text fontSize="sm" fontWeight="600" color={textColor}>
            •
          </Text>
          <HStack spacing={1} alignItems="center" d={["none", "none", "flex"]}>
            {article.tag_list.map((tag) => (
              <Tag
                size="sm"
                padding="0 3px"
                key={tag}
                colorScheme={getTagColor(tag)}
              >
                {tag}
              </Tag>
            ))}
          </HStack>
        </HStack>
        <HStack spacing={1} alignItems="center" d={["flex", "flex", "none"]}>
          {article.tag_list.map((tag) => (
            <Tag
              size="sm"
              padding="0 3px"
              key={tag}
              colorScheme={getTagColor(tag)}
            >
              {tag}
            </Tag>
          ))}
        </HStack>
        <Text align="left" fontSize="md" noOfLines={4} color={textColor}>
          {article.description}
        </Text>
      </VStack>
    </StaggerChildren>
  );
};

export default PostCard;
